// lang/zh.js
import zhLocale from "element-ui/lib/locale/lang/zh-CN";

export default {
	systemName: "后台管理",
	navbar: {
		dashboard: "首页",
		logOut: "退出登录",
		profile: "个人中心",
		theme: "换肤",
		size: "布局大小",
	},
	header: {
		title: "系统登录",
		logIn: "登录",
		usernameTips: "请输入用户名",
		passwordTips: "请输入密码",
	},
	leftSidebar: {
		homePage: "首页",

		basicSetting: "基础设置管理",
		country: "国家管理",
		timedTask: "定时任务",
		authority: "权限管理",
		accountAuthority: "账户权限管理",
		express: "快递管理",
		backlogJump: "待办跳转管理",
		language: "语言管理",
		crowd: "人群管理",
		trackingTimeManagement: "跟踪时间管理",

		commodity: "商品管理",
		fullCustom: "全定制商品管理",
		surcharge: "附加费管理",
		project: "项目管理",
		productCategory: "产品类别",
		colourAtla: "色卡",
		iconLibrary: "图标库",
		semiCustom: "半定制商品管理",
		classify: "半定制分类",
		colourAtla2: "色卡库",
		property: "属性库管理",
		categoryLibrary: "类目库管理",
		commodityWarehouse: "商品库管理",

		addEditCommodity: "新增/修改商品",

		subjectManage: "主题管理",
		designSystem: "设计系统管理",
		art: "Art管理",
		color: "颜色管理",
		templateManage: "模板管理",

		viewDatabase: "视图资料库管理",

		sharePictures: "共享图片库",

		allianceBusiness: "加盟商管理",
		uspp: "USPP管理",
		websiteProject: "网站项目管理",
		monthInvoice: "月结发票",
		pages: "页面管理",
		relation: "联系管理",
		faq: "FAQ管理",
		resource: "资源管理",
		agent: "Agent管理",
		subscriptionQuery: "订阅查询管理",
		agentClause: "代理商条款",
		agentManagement: "代理商管理",
		agentWebsite: "代理商网站管理",

		franchiser: "经销商管理",
		otoPage: "oto页面管理",
		setMeal: "套餐管理",
		extraPackage: "额外套餐管理",
		dealerAuthority: "经销商权限管理",
		dealerRole: "经销商角色管理",
		productOpinion: "产品意见管理",
		newsContent: "新闻内容管理",

		referral: "转介绍",
		referralStatistics: "转介绍统计",
		virtualA: "虚拟A",
		practicalA: "实际A",
		finished: "已结束",
		taskCheck: "任务检查",
		taskAssessment: "任务考核",
		referralConfiguration: "转介绍配置管理",
		referralLevel: "转介绍等级管理",
		integralWithdrawal: "积分提现",

		inquiryOrder: "询盘订单管理",

		inquiry: "询盘",
		inquiryDetails: "询盘详情",
		order: "订单",
		drawingUpload: "图稿上传",
		orderDetails: "订单详情",

		inquiryList: "询盘列表",
		linkOrder: "链接订单",
		directOrder: "直接订单",
		DOrder: "3D订单管理",
		allocationConfiguration: "分配配置",
		manufactureOrder: "生产单管理",
		factoryQuotation: "工厂报价参数管理",
		projectQuotation: "报价参数列表",
		projectArguments: "参数库配置",

		produceSingleList: "生产单列表",
		customsDeclaration: "报关单",
		shipments: "发货",
		imageDownload: "图稿下载",
		orderQuantity: "订单数量",
		orderStatus: "订单状态",
		actualShipments: "实际发货数量",
		shipAddress: "发货地址",
		acceptor: "受理人员",
		statusDropdownBox: "生产单状态下拉框",
		scheduleDropdownBox: "织带的生产进度下拉框",
		financialProgressDropdownBox: "财务进度下拉框",
		purchaseOrderStatus: "采购单状态下拉框",
		financialProgress: "财务进度",
		offerOrNot: "是否报价",
		creationTime: "创建时间",
		deliveryTime: "交货时间",
		salesman: "业务员",
		invoiceNumber: "发票号",
		orderNumber: "订单号",
		addInvoice: "发货单(新增)",
		invoiceSkipPrinting: "发货单号跳转打印",
		listInvoice: "列表发货单(是否显示)",
		deleteProblemList: "批量删除问题单",
		shippingList: "发货列表(交货时间)",
		deliveryTimeState: "发货列表(订单状态)",
		buyingSituation: "采购情况",
		questionnaireTopMenu: "问题单顶部菜单(点击)",
		abnormalTopMenu: "异常单顶部菜单(点击)",
		backOrder: "延期订单顶部(点击)",
		giftOrder: "赠品订单",
		printDispatchBill: "打印发货单",
		addDispatchBill: "发货单",
		purchasePrinting: "采购打印",
		buyerBankAccountInformation: "采购商银行账号信息",
		buyerInformation: "采购商信息",
		questionnaire: "问题单",

		publicParameters: "公共参数管理",
		parameterType: "参数类型管理",
		purchasingAgent: "采购商管理",
		ProductionOrderStatistics: "生产单统计",
		kunshanProduct: "昆山生产单统计",
		changdeProduct: "常德生产单统计",
		ribbonstatistics: "织带厂统计",
		Purchasestatistics: "采购单统计",

		favourable: "优惠管理",
		discountCoupon: "优惠券规格",
		discountCouponList: "用户优惠券列表",

		paymentCenter: "支付中心管理",

		userManage: "用户管理",
		memberInformation: "会员信息管理",
		memberOrder: "会员等级管理",

		affiliateMarketing: "联盟营销外链管理",
		basicInformation: "基础信息录入",
		blogger: "博主管理",
		classicCase: "经典案例申请",
		orderSubmission: "订单提交申请",
		sponsoredLinks: "赞助链接管理",
		statisticsQuery: "统计查询",

		finance: "财务管理",
		profitCenter: "利润中心",
		waybillData: "运单数据",
		profitData: "利润数据",
		abnormalWaybill: "异常运单",
		abnormalWeightWaybill: "重量异常运单",
		refund: "退款管理",
		export: "财务用友导出",
		orderExport: "订单导出",

		contentManagement: "内容管理",
		websiteStyle: "网站风格",
		template: "模板",
		subjectColor: "主题色",
		logo: "logo库",
		video: "video库",

		labelManagement: "标签管理",

		customProducts: "自定义产品",
		classOfProduct: "自定义产品类别",
		categoricalAttributes: "自定义产品类别属性",
		linkedProducts: "链接产品",
		inventory: "库存产品",

		selfOperatingPlatform: "自营平台管理",
		websiteContent: "网站内容管理",
		websitePrice: "网站价格控制",

		xuanzexiangmu: "选择项目",
		xuanzeyuyan: "选择语言",
		tuichu: "退出",
		xiugaimima: "修改密码",
		switchAccount: "切换账号",
		roleManagement: "角色管理",
		chakan: "查看，删除",

		shengchandanyemianquanxian: "生产单详情",
		zhidinggongchang: "指定工厂 - 编辑",
		youjianchaosong: "邮件抄送发货员 - 编辑",
		dingdanzongshuliang: "订单总数量 - 编辑",
		yangpinshuliang: "样品数量 - 编辑",
		jiaohuoriqi: "交货日期 - 编辑",
		dingdanqingkuang: "订单情况 - 编辑",
		quedingshengchanZong: "确定生产(总) - 编辑",
		chanpinleixing: "产品类型 - 编辑",
		caigopeijiandananniu: "采购配件单按钮 - 编辑",
		zhuancaigodananniu: "转采购单按钮 - 编辑",
		dingdanzhuangtai: "订单状态(参数) - 编辑",
		xudanhao: "续单号(参数) - 编辑",
		shuliang: "数量(参数) - 编辑",
		xiugaizhidinggongchang: "修改指定工厂(参数)",
		youjianchaosongEdit: "邮件抄送发货员(参数) - 编辑",
		caizhi: "材质(参数) - 编辑",
		gongyi: "工艺(参数) - 编辑",
		gongyicolor: "工艺颜色(参数) - 编辑",
		chanpinsize: "产品尺寸(参数) - 编辑",
		chanpinsizestr: "产品尺寸Str(参数) - 编辑",
		hodu: "厚度(参数) - 编辑",
		diandu: "电镀(参数) - 编辑",
		yinshua: "印刷(参数) - 编辑",
		yinshuacolor: "印刷颜色(参数) - 编辑",
		cemiandiaoke: "侧面雕刻(参数) - 编辑",
		bian: "边(参数) - 编辑",
		paoguang: "抛光(参数) - 编辑",
		beimo: "背膜(参数) - 编辑",
		qita: "其它(参数) - 编辑",
		qitaNumber: "其它数量(参数) - 编辑",
		peijian: "配件(参数) - 编辑",
		peijianNumber: "配件数量(参数) - 编辑",
		baozhuang: "包装(参数) - 编辑",
		muju: "模具(参数) - 编辑",
		addshengchanjiage: "添加生产价格按钮(子) - 编辑",
		delshengchanjiage: "删除生产价格(子)-编辑",
		shengchanjiageNume: "生产价格(名称)-编辑",
		shengchanjiageNumber: "生产价格(数量)-编辑",
		shengchanjiagePrice: "生产价格(单价)-编辑",
		shengchanjiageMuJu: "生产单价(模具费)-编辑",
		shengchanjiageQita: "生产价格(其它)-编辑",
		quedingshengchanZi: "确定生产(子)-编辑",
		cancelOrder: "取消订单,停止生产(子)-编辑",
		reasonForDelay: "延期原因(子)-编辑",
		businessModificationContentZi: "业务修改内容(子)-编辑",
		truePrice: "真实价格-查看",
		dimensionWidth: "尺寸宽度(参数)-编辑",
		dimensionLength: "尺寸长度(参数)-编辑",
		addOrderBut: "采购-新增订单按钮-查看",
		orderDetailsArt: "采购-订单详情-art图-查看",
		POFImageUploading: "采购-POF图稿上传和下载-查看",
		artworkImgUploading: "采购 - Artwork图片上传-查看",
		affirmPause: "采购 - 确认和暂停采购 - 查看",
		purchaseProductDetails: "采购 - 采购产品详情 - 查看",
		caigoTruePrice: "采购 - 真实价格 - 查看",
		expressPictures: "采购 - 快递图片 - 查看",
		purchasingNotes: "采购 - 采购备注 - 查看",
		financialNotes: "采购 - 财务备注 - 查看",
		businessModificationContent: "采购 - 业务修改内容 - 查看",
		procurementSchedule: "采购 - 采购进度 - 查看",
		caiGoFinancialProgress: "采购 - 财务进度 - 查看",
		waybillInformation: "采购 - 运单信息 - 查看",
		caiGoOrderDetailsArt: "采购 - 订单详情 - art图-编辑",
		artworkImg: "采购 - artwork图片 - 编辑",
		reviewPurchasePrice: "采购 - 审核采购价 - 查看",
		businessModificationContentBianJi: "采购 - 业务修改内容 - 编辑",
		affirmPauseBianJi: "采购 - 确认和暂停采购 - 编辑",
		caigoTruePriceBianJi: "采购 - 真实价格 - 编辑",
		purchasingNotesBianJi: "采购 - 采购备注 - 编辑",
		procurementScheduleBianJi: "采购 - 采购进度 - 编辑",
		caiGoFinancialProgressBianJi: "采购 - 财务进度 - 编辑",
		waybillInformationBianJi: "采购 - 运单信息 - 编辑",
		reviewPurchasePriceBianJi: "采购 - 审核采购价 - 编辑",
		redoOrderButton: "采购 - 重做订单按钮 - 查看",
		remark: "备注(子) - 编辑",
		shipAddressBianJi: "发货地址 - 编辑",
		mountings1: "配件(1) - 编辑",
		mountings2: "配件(2) - 编辑",
		shengchanjiage: "生产价格 - 查看",
		totalProductionOrderBtn: "总生产单重做按钮 - 编辑",
		subproductionOrderBtn: "子生产单重做按钮 - 编辑",
		allAccessoriesFactoryNo: "配件采购单号 - 编辑",
		ribbonProductionNo: "织带生产单号 - 编辑",
		factorySchedule: "工厂进度 - 编辑",
		ribbonProductionOrder: "织带生产单 - 查看",
		purchaseAccessoriesList: "采购配件单 - 查看",
		subpurchaseOrder: "转采购单 - 查看",
		redoOrder: "重做单 - 查看",
		subfixedProduction: "子确定生产 - 查看",
		reviewProductionPriceButBianJi: "审核生产价格按钮 - 编辑",
		reviewProductionPriceBut: "审核生产价格按钮 - 查看",
		confirmPurchase: "采购弹窗-确认采购-编辑（经理配）",
		sampleStatus: "样品状态 - 编辑",
		natureOrder: "订单性质 - 编辑",
		orderProductionStatus: "订单生产情况 - 编辑",
		modifyBuyerType: "采购-修改采购员和采购类型",
		webbingPopover: "织带弹窗-确定生产 - 编辑（经理配）",

		caiGoDanyemianquanxian: "采购单详情",
		orderDetailsArt2: "订单详情-art图",
		PDFImageUploading: "PDF图稿上传和下载",
		caiGoArtworkImgUploading: "Artwork图片上传",
		confirmSuspendPurchases: "确认和暂停采购",
		caiGoPurchaseProductDetails: "采购产品详情",
		trueprice2: "真实价格",
		caiGoExpressPictures: "快递图片",
		caiGoPurchasingNotes: "采购备注 - 查看",
		financialNotesView: "财务备注 - 查看",
		businessModificationContentView: "业务修改内容 - 查看",
		procurementProgressView: "采购进度 - 查看",
		financialProgressView: "财务进度 - 查看",
		waybillInformationView: "运单信息 - 查看",
		orderdetailsEdit: "订单详情-art图 - 编辑",
		artworkImageEdit: "artwork图片 - 编辑",
		reviewPurchasePriceView: "审核采购价 - 查看",
		businessModificationContentEdit: "业务修改内容 - 编辑",
		confirmSuspendPurchasesEdit: "确认和暂停采购 - 编辑",
		truepriceEdit: "真实价格 - 编辑",
		caiGoPurchasingNotesEdit: "采购备注 - 编辑",
		procurementProgressEdit: "采购进度 - 编辑",
		financialProgressEdit: "财务进度 - 编辑",
		waybillInformationEdit: "运单信息 - 编辑",
		reviewPurchasePriceEdit: "审核采购价 - 编辑",
		redoOrderButtonView: "重做订单按钮 - 查看",
		addOrderButView: "新增订单按钮 - 查看",
		printPageView: "采购单打印页面 - 查看",
		financialNotesEdit: "财务备注 - 编辑",
		PDFImageUploadingEdit: "PDF图稿上传和下载 - 编辑",
		purchaseCompletedView: "采购是否完成 - 查看",
		purchaseCompletedEdit: "采购是否完成 - 编辑",
		caiGoExpressPicturesEdit: "快递图片 - 编辑",
		confirmPurchaseEdit: "确认采购 - 编辑",
		caiGoPurchaseProductDetailsEdit: "采购产品详情 - 编辑",
		sampleStatusView: "样品状态 - 查看",

		orderPurchaseStatus: "订单采购情况--编辑",
		modifyBuyerType2: "修改采购员和采购类型",
		addMenu: "添加一级菜单",

		configurationManagement: "配置管理",
		projectConfiguration: "项目配置",
		projectTypeConfiguration: "项目类型配置",

		editPermission: "编辑权限",
		delPermission: "删除权限",
		addPermission: "添加权限",

		detailsPermission: "详情权限",
		printPermission: "打印权限",
		linkArtworkPermission: "图稿链接权限",
		messageAlertPermission: "消息提示权限",
		imageUploadingPermission: "图稿上传权限",
		writePermission: "发送信息权限",

		userNotesPermission: "用户备注权限",
		remarksInquiryPermission: "询盘备注权限",
		changeSalesmanPermission: "更改业务员权限",
		orderStatusEdit: "订单状态编辑",
		inquiryStatusEdit: "询盘状态编辑",

		imageUploadingEdit: "图稿上传编辑",
		deleteArtwork: "删除图稿",
		artworkSwitchLock: "图稿开关锁",

		invoicePermission: "发票权限",
		sanDOrdersPermission: "3D订单权限",
		addRefundOrderPermission: "添加退款单权限",
		equipmentPermission: "设备权限",
		makeInvoicesPermission: "制作发票权限",

		userInformationEditPermission: "用户信息编辑权限",
		orderStatusEditPermission: "订单状态编辑权限",
		payStatusEditPermission: "支付状态编辑权限",
		orderInfoEditPermission: "订单信息编辑权限",
		transportInformationEditPermission: "运输信息编辑权限",
		siteEditPermission: "地址编辑权限",

		viewAllOrdersPermission: "查询所有订单权限",

		gmailManage: "邮件管理",
		gmailCenter: "邮件中心",
		gamilGroup: "成员分组",
		gmailSign: "邮件签名",
		gmailSetting: "邮件设置",
		financialOffline: "财务线下收款",
		feeData: "费用数据",

		developmentTaskList: "开发任务列表",
		promotionCenter: {
			seriesActivityManagement: {
				addSeries: "创建系列",
				claimDetails: "领取明细",
				createActivity: "创建活动",
				view: "查看",
				edit: "编辑",
				copy: "复制",
				enableDisable: "启用/停用",
			},
			siteAdministration: {
				applyPermission: "申请权限",
				view: "查看",
				delete: "删除",
				resubmit: "重新提交",
				examineApprove: "审批",
			},
		},
	},
	inquiryPage: {
		dealeSelfOperatedPlatformrs: "自营平台",
		dealer: "O2O经销商",
		allianceBusiness: "USPP加盟商",
		timeFilter: "选择日期:",
		date: "日期",
		reset: "重置",
		add: "添加",
		delete: "删除",
		drawingUpload: "图稿上传",

		uploadOrder: "上传订单",
		orderNo: "订单号/邮箱:",
		neiRong: "请输入内容",
		price: "价格:",
		number: "运单号:",
		orderState: "订单状态:",
		pleaseSelect: "请选择",
		orderPayoutStatus: "订单支付情况:",
		hoFu: "后付订单",
		weiZhiFu: "未支付订单",
		yiZhiFu: "已支付订单",
		yiTuiKuang: "已退款订单",
		CaliforniaOrder: "仅查看加州订单",
		operationPlatform: "运营平台:",
		xianShiShuLiang: "显示数量:",
		chaXun: "查询",

		addTuiKuanDan: "添加退款单",
		manufactureOrder: "生产单号",
		purchaseOrders: "采购单号",
		ueserRemark: "用户备注",
		fuJian: "附件",
		zhuangTai: "状态",
		daiQueRen: "待确认",
		yiQueRen: "已确认",
		weiTongGuo: "未通过",
		unitCost: "图稿成本单价",
		toolingCharge: "图稿模具费",
		additionalCharge: "图稿附加费",
		productParameter: "图稿产品参数",
		orderInvoice: "制作订单发票",
		optionDate: "选择日期",
		quantity: "数量:",
		unitPrice: "单价:",
		toolingCharge2: "模具费",
		deliveryDiscount: "交期折扣:",
		aggregate: "总计:",
		describe: "描述:",
		remark2: "备注:",
		definiteGeneration: "确定生成:",
		addInvoice: "(确定发票后不可修改)",
		submit: "提交",
		fanHui: "返回",
		customerName: "客户名称",
		orderTotal: "订单总额",
		business: "业务",
		invoiceProgress: "发票进度",
		xiangmuliebiao: "项目列表:",
		xuanzexiangmu: "选择项目",
		guanjianzi: "关键字:",
		time: "时间",
		to: "至",
		startDate: "开始日期",
		dateClosed: "结束日期",
		yewuyuan: "业务员:",
		search: "搜索",
		orderNumber: "订单号",
		invoiceNumber: "发票号",
		productClass: "产品类别",
		remark: "备注",
		sanDModeling: "所需3d建模数量",
		creationTime: "创建时间",
		operation: "操作",
		edit: "编辑",
		suoxushuliang: "所需数量",
		videoXinXi: "视频信息",
		addVideo: "添加视频",
		cancel: "取 消",
		confirm: "确定",
		xinJian: "新建",
		sheJiZhong: "设计中",
		sheJiWanCheng: "设计完成",
		aweek: "最近一周",
		onemonth: "最近一个月",
		trimester: "最近三个月",

		dingdanhao: "订单号 / 邮箱 / Title",
		cuikuanTime: "催款时间",
		preview: "预览",
		uploading: "上传",
	},
	refundForm: {
		newRefundOrder: "经理审批中",
		managerApproved: "经理审批通过",
		adminApproved: "admin审批通过",
		rejected: "审批不通过",
		refunded: "已退款",

		tuikuanOrder: "退款订单",
		shenpitongguo: "审批通过",
		quanbu: "全部",
		shenpi1: "经理审批中",
		shenpi2: "经理审批通过",
		shenpi3: "经理审批不通过",
		shenpi4: "admin审批通过",
		shenpi5: "admin审批不通过",
		shenpi6: "已退款",
		fapiao: "发票号/邮箱..",
		bufentuikuan: "部分退款",
		quanbutuikuan: "全部退款",
		qingxuanze: "请选择",
		name: "名称",
		email: "邮箱",
		phone: "电话",
		fapiaohao: "发票号",
		tuikuanyuanyi: "退款原因",
		reasonForRefundTipDefault: "（给领导审批，填中文）",
		reasonForRefundTipCreditMemo: "（带到Credit Memo，填英文）",
		department: "责任部门",
		tuikuanleixing: "退款类型",
		tuikuanjine: "退款金额",
		qingshuru: "请输入金额",
		zhifufanshi: "支付方式",
		yuanyibeizhu: "原因备注",
		shenhestate: "审核状态",
		shenPistate: "审批状态",
		baocun: "保存",
		quxiao: "取消",
		zhizuotuikuandan: "制作退款单",

		operate: "操作",
		createTime: "创建时间",
		orderID: "订单号",
		customers: "用户信息",
		salesman: "业务员",
		jingli1: "经理审批中",
		jingli2: "admin审批通过",
		jingli3: "审批不通过",
		chongxin1: "重新审批中",
		chongxin2: "经理审批通过",
		tongyi: "同意",
		tuikuanstate: "退款状态",
		orderDetails: "订单详情",
		jiaoqiyanwu: "交期延误",
		sejiyaoqiu: "改设计要求",
		tuishui: "退税",
		zhiliangwenti: "质量问题",
		quxiaoOrder: "取消订单",
		qita: "其他",
	},
	uploadDraft: {
		caozuorizhi: "操作日志",
		addProductInformation: "添加产品信息",
		name: "名称",
		invoiceMumber: "发票号",
		webLinks: "所属网站链接",
		userEmail: "客户邮箱",
		uploadArtwork: "上传图稿与定制信息",
		upload: "点击添加新产品",
		upload2: "(或拖拽图片至此)",
		defaultPicture: "添加附加费",
		tariffQuantity: "关税数量",
		productionCapture: "被生产单抓取",
		artSwitchLock: "图稿开关锁",
		productClass: "产品类别",
		alternateDrawing: "替换当前图稿",
		addDrawing: "添加图稿",
		addText: "添加定制信息(单价请填写折扣前单价)",
		copy: "复制Item",
		quantity: "数量",
		price: "单价",
		toolingCharge: "模具费",
		extraFee: "额外费用",
		deliveryDiscount: "交期折扣",
		aggregate: "总计",
		xuanzefuzhi: "选择复制的Item",
		describe: "描述",
		parameterFormat: "参数格式: key:value,冒号后无空格,回车换行(英文格式)",
		remark: "备注",
		submit: "提交",
		staging: "暂存",
		sendEmail: "发送邮件",
		tishi: "提示",
		tishiText: "你有一条缓存数据,请确认是否展示？",
		quxiao: "取 消",
		queding: "确 定",
		shiyin: "丝印",
		beimo: "背膜",
		printing: "印刷",
		medallionRibbon: "配有奖章织带",
		beginMs: "开启免税",
		msZm: "免税证明",
		uploadMsZm: "上传免税证明",
		gqTime: "过期时间",
	},
	Invoice: {
		SetupFee: "Setup Fee",
		PleaseAttention: "Please Attention",
		MoldSetupFee: "Mold/Setup Fee",

		phone: "Phone",
		email: "Email",
		from: "From",
		to: "To",
		attn: "Attn",
		lNVOICE: "lNVOICE",
		invoiceNo: "Invoice No",
		date: "Date",
		paymentMethod: "Payment Method",
		title: "Title",
		no: "No",
		artwork: "Artwork",
		item: "Item",
		description: "Description",
		qty: "Qty",
		unitPrice: "Unit Price",
		moldFee: "Mold Fee",
		extraFee: "Extra Fee",
		amount: "Amount",
		subtotal: "Subtotal",
		apoShippingFee: "Apo Shipping Fee",
		taxPrice: "Tax Price",
		couponAdditionalCosts: "Additional Costs ",
		firstOrderCodeDisc: "First Order Discount ",
		promoCode: "Promo Code ",
		specialCode: "Special Group Offers",
		additionalCode: "$1 for a $75 Voucher",
		pointsDeduct: "Points Deduct ",
		giftVouchers: "Gift Vouchers",
		couponDiscount: "Coupon Discount ",
		orderDiscount: "Order Discount ",
		extraDiscount: "Extra Discount ",
		finalPrice: "Final Price",
		finalTotalPrice: "Final Total Price",
		estimatedShipDate: "Estimated Ship Date",
		notes: "Notes",
		shipTo: "Ship To",
		name: "Name",
		check: "Check",
		APOFPOAddress: "APO/FPO Address",
		Address: "Address",
		GrandTotal: "Grand Total",
		AmountPaid: "Amount Paid",
		BalanceDue: "Balance Due",
		UserLevelDiscount: "User Level Discount ",
	},
	draft: {
		itemName: "Item Name",
	},
	member: {
		member1: "Silver Member",
		member4: "Gold VIP Member",
		member5: "Platinum VIP Member",
	},
	emailManage: {
		inbox: "收件箱",
		outbox: "发件箱",
		writeEmail: "写邮件",
		emailSign: "签名",
		selectEmail: "选择邮箱",
		addEmail: "添加邮箱",
		emailName: "邮箱名称",
		emailAccount: "邮箱账户",
		emailPassword: "邮箱密码",
		emailMembers: "邮箱用户",
		addMember: "添加用户",
		emailList: "邮箱列表",
		deleteGmail: "删除邮箱",
		updateGmail: "更新邮箱",
		users: "总后台用户",
		gmailUserName: "用户名称",
		sender: "发件人",
		Sign: "签名:",
		sign: "签名名称:",
		save: "保存设置",
		save2: "保存",
		signDesc: "(附加在所有外发邮件末尾)",
		createSign: "新建签名",
		manageSign: "管理签名",
		changeEmail: "管理邮箱",
		editSignName: "修改签名名称",
		defaultSign: "默认签名设置",
		forNewEamil: "用于新电子邮件",
		forReply: "用于回复/转发",
		signSetting: "在回复邮件中的引用文字之前插入签名，并删除其前面的“--”线。",
		sendTo: "发送至",
		forwardTo: "转发至",
		ccTo: "抄送至",
		replay: "回复",
		replyAll: "回复所有",
		relay: "转发",
		relayAll: "转发所有",
		send: "发送",
		attachment: "个附件",
		memberId: "US办公室成员ID",
		memberName: "US办公室成员姓名",
		salesmanNumber: "分配业务员数量",
		addSalesman: "添加业务员",
		salesman: "业务员",
		mySalesman: "我的业务员",
		salesmanName: "业务员名",
		ModifyingInformation: "修改信息",
		reset: "重置",
		cancel: "取消",
		create: "创建",
		confirm: "提交",
		delete: "删除",
		edit: "操作",
		index: "首页",
		showDetail: "查看详情",
		placeholder: "搜索办公室成员姓名",
		recipient: "收件人",
		expand: "展开",
		retract: "收起",
		newEmail: "新邮件",
		subject: "主题",
		makeCopy: "抄送",
	},
	finance: {
		offlineCollectionFinancial: "财务线下收款 - 财务",
		offlineCollectionSalesman: "财务线下收款 - 业务",
	},
	kpiManage: {
		basicSetting: "客服绩效管理",
		completionRate: "成单绩效管理",
		completionRateOne: "个人成单绩效报表",
		completionRateAll: "综合成单绩效报表",
		repurchaseRate: "复购绩效管理",
		repurchaseRateAll: "综合复购绩效报表",
		repurchaseRateOne: "个人复购绩效报表",
		commentRate: "好评率数据上传",
		kpiRuleManagement: "绩效规则管理",
		historicalRankingRecord: "历史排名记录表",
		comprehensiveHistoricalRanking: "综合历史排名纪录表",
		individualHistoricalRanking: "个人历史排名纪录表",
	},
	dataAnalysis: {
		basecSetting: "数据分析",
		salesDataDataAnalysis: "销售数据分析",
		inquiryDataAnalysis: "询盘数据分析",
		tagsDataAnalysis: "标签数据分析",

		salesDataAnalysisOverview: "概况",
		platformDataAnalysis: "平台数据分析",
		countryDataAnalysis: "国家数据分析",
		categoryDataAnalysis: "品类数据分析",
		peopleDataAnalysis: "人群数据分析",
		crowdTags: "人群标签",
		crowdTagDataAnalysis: "人群标签数据分析",

		platformDataAnalysisDetail: "平台数据分析详情",
		countryDataAnalysisDetail: "国家数据分析详情",
		categoryDataAnalysisDetail: "品类数据分析详情",
		peopleDataAnalysisDetail: "人群数据分析详情",
		crowdTagDataAnalysisDetail: "人群数据分析详情",

		orderAmountSelectRule: "订单数量查阅权限",
		totalPriceSelectRule: "销售总额查阅权限",
		costAmountSelectRule: "成本总额查阅权限",
		grossProfitSelectRule: "毛利总额查阅权限",
		expensesSelectRule: "费用总额查阅权限",
		netProfitSelectRule: "净利总额查阅权限",
		quantitySelectRule: "销售数量查阅权限",
		customerCountAmountSelectRule: "消费客户数查阅权限",
		customerPriceAverageSelectRule: "平均客单价查阅权限",

		configView: "数据可视配置",
	},
	treeData: {
		name: "商品类目管理",
	},
	login: {
		switchAccount: "切换账号",
		bindAccount: "绑定账号",
		bindAccountTips: "请绑定一个总后台账号",
		selectAccount: "选择账号",
		selectAccountTips: "当前绑定了多个总后台账号，请双击选择",
		back: "返回",
		addBinding: "新增绑定",
		binding: "绑 定",
		ok: "好 的",
		generalBackendAccount: "总后台账号",
		generalBackendPassword: "总后台密码",
		accountErrorTips: "总后台账号不能为空",
		passwordErrorTips: "总后台密码不能为空",
	},
	...zhLocale,
};
