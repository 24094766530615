import nlLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Instellingskosten",
		PleaseAttention: "Attentie alstublieft",
		MoldSetupFee: "Vorm-/inrichtingskosten",

		invoice: "FACTUUR",
		PONumber: "Postbus nummer",
		reference: "Referentie",
		HSCODE: "HS-CODE",
		PURCHASEINVOICE: "AANKOOPFACTUUR",
		productPrintNo: "productAfdruknr",
		BILLTO: "REKENING NAAR",
		SHIPTO: "VERZEND NAAR",
		ProductDescription: "Productomschrijving",
		total: "Totaal",
		PaymentStatus: "Betalingsstatus",
		checkInfo: "Controleer informatie",
		bankInfo: "Bankgegevens",
		payOnline: "Betaal online",
		checkPay: "Controleer: Betaal en mail naar onderstaande informatie",
		attentionPlease: "Let op",
		directorOperations: "Uitvoerend Directeur",
		signature: "Handtekening",
		nodesText: "Als u via de bank betaalt, zorg er dan voor dat u uw betalingsgegevens vermeldt",
		nodesText2: "in de nota.",
		thankbusiness: "Bedankt voor uw zaken!",
		paid: "BETAALD",
		unpaid: "ONBETAALD",
		payInstall: "Gedeeltelijke betaling, wacht op het saldo.",
		remember:
			" voor offshore-rekeningen en buitenlandse-valutarekeningen die door buitenlandse klanten worden gebruikt om naar onze bank over te maken, moet het gebruik van het geld 'Betaling voor goederen' zijn, of u kunt dit blanco laten.",
		extraText: [
			"",
			"Spoedkosten",
			"Zeefdruktoeslag",
			"Kosten voor terugvorm",
			"Gedrukte kosten",
			"Materiaalkosten zinkspuitgieten",
			"Extra vracht",
			"Kosten voor gelicentieerde producten",
			"Hoeveelheid",
		],
		TaxPrice: "Belasting Prijs",
		discountedAmount: "Gereduceerd bedrag",

		card: "1. Creditcard: VISA / Mastercard / AMEX en Discover",
		bankNote:
			"Let op: Als u in een andere valuta dan de EUR wilt betalen, neem dan contact op met onze klantenservice.",
		checkFollow: "2. U vindt onze cheque-informatie als volgt:",
		mention: "Bij betaling graag het factuurnummer vermelden. Bedankt!",
		topic: "Onderwerp: Financieel advies",
		invoiceDate: "Factuur datum",
		invoiceNumber: "Factuurnummer",
		excluding: "Subtotaal exclusief",
		phone: "Telefoon",
		email: "E-mail",
		from: "Van",
		to: "Naar",
		attn: "Tav",
		lNVOICE: "FACTUUR",
		PROFORMA: "PROFORMA FACTUUR",
		invoiceNo: "Factuur nr",
		date: "Datum",
		paymentMethod: "Betalingsmiddel",
		terms: "Voorwaarden",
		title: "Titel",
		no: "Nee",
		artwork: "Kunstwerk",
		item: "Item",
		description: "Beschrijving",
		qty: "Aantal",
		unitPrice: "Eenheid prijs",
		moldFee: "Schimmel vergoeding",
		extraFee: "Extra vergoeding",
		amount: "Hoeveelheid",
		subtotal: "Subtotaal",
		apoShippingFee: "Apo-verzendkosten",
		taxPrice: "Belasting Prijs",
		couponAdditionalCosts: "Bijkomende kosten",
		firstOrderCodeDisc: "Korting bij eerste bestelling",
		promoCode: "Promotiecode",
		specialCode: "Speciale groepsaanbiedingen",
		custompensCode: "$1 voor 20 stks Pennen",
		additionalCode: "$ 1 voor een voucher van $ 75",
		pointsDeduct: "Punten aftrek",
		points: "Punten",
		giftVouchers: "Cadeaubonnen",
		couponDiscount: "Kortingsbon",
		orderDiscount: "Korting bestellen",
		extraDiscount: "Extra korting",
		finalPrice: "definitieve prijs",
		finalTotalPrice: "Definitieve totaalprijs",
		estimatedShipDate: "Geschatte verzenddatum",
		notes: "Notities",
		shipTo: " Verzend naar",
		name: "Naam",
		check: "Rekening",
		APOFPOAddress: "APO/FPO-adres",
		Address: "Adres",
		GrandTotal: "Eindtotaal",
		AmountPaid: "Betaald bedrag",
		BalanceDue: "Saldo verschuldigd",
		UserLevelDiscount: "Korting op gebruikersniveau",
	},
	Quotation: {
		setupFee: "Installatiekosten",
		free: "VRIJ",
		company: "Bedrijf",
		off: "UIT",
		additionalCosts: "Bijkomende kosten",
		firstOrderDiscount: "Korting bij eerste bestelling",
		specialGroup: "Speciale groepsaanbiedingen",
		pointsDeduct: "Punten aftrekken",
		giftVouchers: "Cadeaubonnen",
		couponDiscount: "Couponkorting",
		goldVipMember: "Gouden VIP-lid",
		extraDiscount: "Extra korting",
		userLevelDiscount: "Korting op gebruikersniveau",
		points: "Punten",
		title: "OFFERTE",
		to: "Naar",
		from: "Van",
		name: "Naam",
		email: "E-mail",
		telephone: "Telefoon",
		country: "Land",
		oid: "Order ID",
		date: "Offerte Datum",
		valid: "Geldig tot",
		contacts: "Contacten",
		currency: "Munteenheid",
		no: "Nee.",
		item: "Item",
		artwork: "Kunstwerk",
		description: "Beschrijving",
		qty: "Hoeveelheid",
		unitPrice: "Eenheid prijs",
		moldFee: "Schimmel vergoeding",
		extraFee: "Extra vergoeding",
		apoShippingFee: "Apo-verzendkosten",
		taxPrice: "Belasting Prijs",
		amount: "Hoeveelheid",
		remarks: "Opmerkingen",
		total: "Totaal",
		titleTerms: "Algemene voorwaarden:",
		first: "1. Gratis artwork en gratis verzending naar de VS / Canada / Europa.",
		second: "2. Bovenstaande informatie is geen factuur en slechts een schatting van de hierboven beschreven diensten/goederen.",
		third: "3. De productie start op de datum waarop we uw goedkeuring en betaling van uw bewijs hebben ontvangen. Gelieve deze link voor uw bestelling te betalen op basis van het goedgekeurde bewijs. (Betalingslink:",
		end: "Bedankt voor uw zaken!",
		tel: "Tel",
		hours: "Kantoortijden",
		chat: "Live chat",
		address: "VS adres",
		hoursItem: "Maandag - vrijdag 8:30 uur tot 17:30 uur Pacific Time (PT)",
		chatItem: "24 uur van maandag tot en met vrijdag",
		addressItem: "20829 Vallei Blvd. Walnoot, CA 91789",
	},
	member: {
		member1: "Zilveren lid",
		member4: "Gouden VIP-lid",
		member5: "Platinum VIP-lid",
	},
	...nlLocale,
};
