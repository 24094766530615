import frLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Frais de configuration",
		PleaseAttention: "Veuillez noter",
		MoldSetupFee: "Frais de moule / installation",
		invoice: "FACTURE",
		PONumber: "Numéro de bon de commande",
		reference: "Référence",
		HSCODE: "CODE SH",
		PURCHASEINVOICE: "FACTURE D'ACHAT",
		productPrintNo: "produitImprimer Non",
		BILLTO: "FACTURER À",
		SHIPTO: "ENVOYEZ À",
		ProductDescription: "Description du produit",
		total: "Total",
		PaymentStatus: "Statut de paiement",
		checkInfo: "Vérifier les informations",
		bankInfo: "Informations bancaires",
		payOnline: "Payer en ligne",
		checkPay: "Vérifiez: Payez et envoyez par courrier aux informations ci-dessous",
		attentionPlease: "N'oubliez pas",
		directorOperations: "directeur des opérations",
		signature: "Signature",
		nodesText: "Si vous effectuez un paiement via la banque, assurez-vous d'inclure votre",
		nodesText2: "dans le mémo.",
		thankbusiness: "Merci pour votre entreprise!",
		paid: "PAYÉ",
		unpaid: "NON PAYÉ",
		payInstall: "Paiement partiel, attendez le solde.",
		remember:
			" pour les comptes offshore et les comptes en devises étrangères utilisés par les clients étrangers pour effectuer des versements à notre banque, l'utilisation des fonds doit être «Paiement de marchandises» ou laisser ce champ vide.",
		extraText: [
			"",
			"Frais de pointe",
			"Frais d'impression en sérigraphie",
			"Frais de moule arrière",
			"Frais imprimés",
			"Coût du matériau de moulage sous pression en zinc",
			"Fret supplémentaire",
			"Frais pour les produits sous licence",
			"Quantité",
		],
		TaxPrice: "Prix des taxes",
		discountedAmount: "Montant réduit",

		card: "1. Carte de crédit : VISA / Mastercard / AMEX et Discover",
		bankNote:
			"Remarque: Si vous souhaitez payer dans une devise autre que l'EUR, veuillez contacter notre représentant du service client.",
		checkFollow: "2. Veuillez trouver nos informations de chèque comme suit:",
		mention: "Lors du paiement, veuillez mentionner le numéro de facture. Merci!",
		topic: "Sujet: Conseils financiers",
		invoiceDate: "Date de facture",
		invoiceNumber: "Numéro de facture",
		excluding: "Sous-total hors",
		phone: "Téléphone",
		email: "E-mail",
		from: "Depuis",
		to: "Pour",
		attn: "À l'attention",
		lNVOICE: "FACTURE",
		PROFORMA: "FACTURE PRO FORMA",
		invoiceNo: "Facture n°",
		date: "Date",
		paymentMethod: "Mode de paiement",
		terms: "Termes",
		title: "Titre",
		no: "Non",
		artwork: "Ouvrages d'art",
		item: "Article",
		description: "Description",
		qty: "Qté",
		unitPrice: "Prix unitaire",
		moldFee: "Frais de moule",
		extraFee: "Frais supplémentaire",
		amount: "Montant",
		subtotal: "Total",
		apoShippingFee: "Frais d'expédition Apo",
		taxPrice: "Prix de taxe",
		couponAdditionalCosts: "Supplément",
		firstOrderCodeDisc: "Réduction sur la première commande",
		promoCode: "Code promo",
		specialCode: "Offres spéciales pour les groupes",
		custompensCode: "1 $ pour 20 stylos",
		additionalCode: "1 $ pour un bon de 75 $",
		pointsDeduct: "Déduire des points",
		points: "Points",
		giftVouchers: "Chèques cadeaux",
		couponDiscount: "Bon de réduction",
		orderDiscount: "Réduction sur la commande",
		extraDiscount: "Remise supplémentaire",
		finalPrice: "Prix final",
		finalTotalPrice: "Prix total final",
		estimatedShipDate: "Date approximative du navire",
		notes: "Remarques",
		shipTo: " Envoyez à",
		name: "Nom",
		check: "Vérifier",
		APOFPOAddress: "Adresse APO/FPO",
		Address: "Adresse",
		GrandTotal: "Total",
		AmountPaid: "Le montant payé",
		BalanceDue: "Solde dû",
		UserLevelDiscount: "Remise au niveau utilisateur",
	},
	Quotation: {
		setupFee: "Frais d'installation",
		free: "GRATUIT",
		company: "Entreprise",
		off: "DÉSACTIVÉ",
		additionalCosts: "Coûts additionnels",
		firstOrderDiscount: "Remise sur la première commande",
		specialGroup: "Offres spéciales groupes",
		pointsDeduct: "Points à déduire",
		giftVouchers: "Chèques cadeaux",
		couponDiscount: "Coupon de réduction",
		goldVipMember: "Membre VIP Or",
		extraDiscount: "Remise supplémentaire",
		userLevelDiscount: "Remise au niveau utilisateur",
		points: "Points",
		title: "CITATION",
		to: "Pour",
		from: "Depuis",
		name: "Nom",
		email: "E-mail",
		telephone: "Téléphone",
		country: "Pays",
		oid: "numéro de commande",
		date: "Date de cotation",
		valid: "Valable jusque",
		contacts: "Contacts",
		currency: "Monnaie",
		no: "Non.",
		item: "Article",
		artwork: "Ouvrages d'art",
		description: "Description",
		qty: "Quantité",
		unitPrice: "Prix unitaire",
		moldFee: "Frais de moule",
		extraFee: "Frais supplémentaire",
		apoShippingFee: "Frais d'expédition Apo",
		taxPrice: "Prix des taxes",
		amount: "Montant",
		remarks: "Remarques",
		total: "Total",
		titleTerms: "Termes et conditions:",
		first: "1. Illustrations gratuites et livraison gratuite aux États-Unis/Canada/Europe.",
		second: "2. Les informations ci-dessus ne constituent pas une facture et uniquement une estimation des services/biens décrits ci-dessus.",
		third: "3. La production commencera à la date à laquelle nous recevrons votre approbation et votre paiement, veuillez payer ce lien pour votre commande en fonction de la preuve approuvée. (Lien de paiement:",
		end: "Merci pour votre entreprise!",
		tel: "Tél",
		hours: "Heures de travail",
		chat: "Chat en direct",
		address: "Adresse aux États-Unis",
		hoursItem: "Du lundi au vendredi de 8 h 30 à 17 h 30, heure du Pacifique (PT)",
		chatItem: "24 heures du lundi au vendredi",
		addressItem: "20829 Boulevard de la Vallée. Noyer, Californie 91789",
	},
	member: {
		member1: "Membre Argent",
		member4: "Membre VIP Or",
		member5: "Membre VIP Platine",
	},
	...frLocale,
};
