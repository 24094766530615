import svLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Inställningsavgift",
		PleaseAttention: "Var snäll och uppmärksamma",
		MoldSetupFee: "Avgift för mögel/installation",

		invoice: "FAKTURA",
		PONumber: "PO-nummer",
		reference: "Referens",
		HSCODE: "HS-KOD",
		PURCHASEINVOICE: "KÖPSFAKTURA",
		productPrintNo: "produktTryck nr",
		BILLTO: "BILL TILL",
		SHIPTO: "FRAKTA TILL",
		ProductDescription: "Produktbeskrivning",
		total: "Total",
		PaymentStatus: "Betalningsstatus",
		checkInfo: "Kontrollera info",
		bankInfo: "Bankinformation",
		payOnline: "Betala online",
		checkPay: "Kontrollera: Betala och skicka till info nedan",
		attentionPlease: "Kom ihåg",
		directorOperations: "Verksamhetsdirektör",
		signature: "Signatur",
		nodesText: "Om du betalar via banken, se till att inkludera din",
		nodesText2: "i memo.",
		thankbusiness: "Tack för din verksamhet!",
		paid: "BETALAT",
		unpaid: "OBETALD",
		payInstall: "Delbetalning, vänta på saldot.",
		remember:
			' För offshorekonto och konto i utländsk valuta som används av utländska kunder för att betala till vår bank, bör användningen av medel vara "Betalning för varor", eller lämna det tomt.',
		extraText: [
			"",
			"Rush Fee",
			"Screentryckavgift",
			"Tillbaka Mögel avgift",
			"Utskriven laddning",
			"Zinkgjutningsmaterialkostnad",
			"Extra frakt",
			"Avgift för licensierade produkter",
			"Antal",
		],
		TaxPrice: "Skattepris",
		discountedAmount: "Rabatterat belopp",

		card: "1. Kreditkort: VISA / Mastercard / AMEX och Discover",
		bankNote: "Obs: Om du vill betala i en annan valuta än EUR, vänligen kontakta vår kundtjänstrepresentant.",
		checkFollow: "2. Vänligen hitta vår checkinformation enligt följande:",
		mention: "När du gör betalningen, vänligen ange fakturanummer. Tack!",
		topic: "Ämne: Ekonomirådgivning",
		invoiceDate: "Fakturadatum",
		invoiceNumber: "Fakturanummer",
		excluding: "Delsumma exklusive",
		phone: "Telefon",
		email: "E-post",
		from: "Från",
		to: "Till",
		attn: "Attn",
		lNVOICE: "FAKTURA",
		PROFORMA: "PROFORMA FAKTURA",
		invoiceNo: "Faktura nr",
		date: "Datum",
		paymentMethod: "Betalningsmetod",
		terms: "Villkor",
		title: "Titel",
		no: "Nej",
		artwork: "Konstverk",
		item: "Artikel",
		description: "Beskrivning",
		qty: "Antal",
		unitPrice: "Enhetspris",
		moldFee: "Mögelavgift",
		extraFee: "Extra avgift",
		amount: "Belopp",
		subtotal: "Delsumma",
		apoShippingFee: "Apo fraktavgift",
		taxPrice: "Skattepris",
		couponAdditionalCosts: "Extra kostnad",
		firstOrderCodeDisc: "Första beställningsrabatt",
		promoCode: "Rabattkod",
		specialCode: "Särskilda grupperbjudanden",
		custompensCode: "$1 för 20st pennor",
		additionalCode: "$1 för en $75-kupong",
		pointsDeduct: "Poängavdrag",
		points: "Poäng",
		giftVouchers: "Presentkort",
		couponDiscount: "Kupongrabatt",
		orderDiscount: "Beställ rabatt",
		extraDiscount: "Extra rabatt",
		finalPrice: "Slutgiltigt pris",
		finalTotalPrice: "Slutligt totalpris",
		estimatedShipDate: "beräknat leveransdatum",
		notes: "Anteckningar",
		shipTo: " Frakta till",
		name: "namn",
		check: "Kolla upp",
		APOFPOAddress: "APO/FPO-adress",
		Address: "Adress",
		GrandTotal: "Totalsumma",
		AmountPaid: "Betalt belopp",
		BalanceDue: "Resterande belopp",
		UserLevelDiscount: "Rabatt på användarnivå",
	},
	Quotation: {
		setupFee: "Installationsavgift",
		free: "FRI",
		company: "Företag",
		off: "AV",
		additionalCosts: "Extrakostnader",
		firstOrderDiscount: "Första beställningsrabatt",
		specialGroup: "Särskilda grupperbjudanden",
		pointsDeduct: "Poängavdrag",
		giftVouchers: "Presentkort",
		couponDiscount: "Kupongrabatt",
		goldVipMember: "Guld VIP-medlem",
		extraDiscount: "Extra rabatt",
		userLevelDiscount: "Rabatt på användarnivå",
		points: "Poäng",
		title: "CITAT",
		to: "Till",
		from: "Från",
		name: "namn",
		email: "E-post",
		telephone: "Telefon",
		country: "Land",
		oid: "Beställnings-ID",
		date: "Offertdatum",
		valid: "Giltig till",
		contacts: "Kontakter",
		currency: "Valuta",
		no: "Nej.",
		item: "Artikel",
		artwork: "Konstverk",
		description: "Beskrivning",
		qty: "Kvantitet",
		unitPrice: "Enhetspris",
		moldFee: "Mögelavgift",
		extraFee: "Extra avgift",
		apoShippingFee: "Apo fraktavgift",
		taxPrice: "Skattepris",
		amount: "Belopp",
		remarks: "Anmärkningar",
		total: "Total",
		titleTerms: "Allmänna Villkor:",
		first: "1. Gratis konstverk och gratis frakt till USA / Kanada / Europa.",
		second: "2. Ovanstående information är inte en faktura utan endast en uppskattning av tjänster/varor som beskrivs ovan.",
		third: "3. Produktionen startar det datum vi får ditt bevisgodkännande och betalning, vänligen betala denna länk för din beställning baserat på det godkända beviset. (Betalningslänk:",
		end: "Tack för din verksamhet!",
		tel: "Tel",
		hours: "Kontorstid",
		chat: "Livechatt",
		address: "USA-adress",
		hoursItem: "Måndag - fredag 8:30 till 17:30 Pacific Time (PT)",
		chatItem: "24 timmar måndag till fredag",
		addressItem: "20829 Valley Blvd. Walnut, CA 91789",
	},
	member: {
		member1: "Silvermedlem",
		member4: "Guld VIP-medlem",
		member5: "Platinum VIP-medlem",
	},
	...svLocale,
};
