import csLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Poplatek za nastavení",
		PleaseAttention: "Prosím, pozor",

		MoldSetupFee: "Poplatek za formu/nastavení",

		invoice: "FAKTURA",
		PONumber: "číslo objednávky",
		reference: "Odkaz",
		HSCODE: "HS KÓD",
		PURCHASEINVOICE: "DOKLAD O NÁKUPU",
		productPrintNo: "produktTisk č",
		BILLTO: "BILL TO",
		SHIPTO: "DOPRAVIT DO",
		ProductDescription: "Popis výrobku",
		total: "Celkový",
		PaymentStatus: "Status platby",
		checkInfo: "Zkontrolujte informace",
		bankInfo: "Bankovní informace",
		payOnline: "Platit online",
		checkPay: "Zkontrolujte: Zaplaťte a pošlete poštou na níže uvedené informace",
		attentionPlease: "Pamatujte",
		directorOperations: "Provozní ředitel",
		signature: "Podpis",
		nodesText: "Pokud provádíte platbu prostřednictvím banky, nezapomeňte uvést své",
		nodesText2: "v poznámce.",
		thankbusiness: "Děkuji za Váš obchod!",
		paid: "ZAPLACENO",
		unpaid: "NEZAPLACENO",
		payInstall: "Částečná platba, počkejte na zůstatek.",
		remember:
			" V případě offshore účtu a účtu v cizí měně, který zahraniční klienti používají k převodu do naší banky, by použití prostředků mělo být „Platba za zboží“ nebo toto pole ponechte prázdné.",
		extraText: [
			"",
			"Poplatek za spěch",
			"Poplatek za sitotisk",
			"Poplatek za zpětné formy",
			"Tištěný poplatek",
			"Náklady na materiál pro tlakové lití zinku",
			"Extra nákladní",
			"Poplatek za licencované produkty",
			"Množství",
		],
		TaxPrice: "Daňová cena",
		discountedAmount: "Zvýhodněná částka",

		card: "1. Kreditní karta: VISA / Mastercard / AMEX a Discover",
		bankNote:
			"Poznámka: Pokud byste chtěli platit v jiné měně než EUR, kontaktujte prosím našeho zástupce zákaznického servisu.",
		checkFollow: "2. Informace o šeku naleznete takto:",
		mention: "Při platbě uveďte číslo faktury. Děkuji!",
		topic: "Téma: Finanční poradenství",
		invoiceDate: "Datum faktury",
		invoiceNumber: "Číslo faktury",
		excluding: "Mezisoučet bez",
		phone: "Telefon",
		email: "E-mailem",
		from: "Z",
		to: "Na",
		attn: "Attn",
		lNVOICE: "FAKTURA",
		PROFORMA: "PROFORMA FAKTURA",
		invoiceNo: "Faktura číslo",
		date: "datum",
		paymentMethod: "Způsob platby",
		terms: "Podmínky",
		title: "Titul",
		no: "Ne",
		artwork: "Umělecké dílo",
		item: "Položka",
		description: "Popis",
		qty: "množství",
		unitPrice: "Jednotková cena",
		moldFee: "Poplatek za formu",
		extraFee: "Extra poplatek",
		amount: "Množství",
		subtotal: "Mezisoučet",
		apoShippingFee: "Poplatek za dopravu Apo",
		taxPrice: "Daňová cena",
		couponAdditionalCosts: "Dodatečné náklady",
		firstOrderCodeDisc: "Sleva na první objednávku",
		promoCode: "Promo kód",
		specialCode: "Speciální skupinové nabídky",
		custompensCode: "1 $ za 20 kusů per",
		additionalCode: "1 $ za voucher v hodnotě 75 $",
		pointsDeduct: "Odečtení bodů",
		points: "Body",
		giftVouchers: "dárkové poukázky",
		couponDiscount: "Kupónová sleva",
		orderDiscount: "Objednávka sleva",
		extraDiscount: "Extra sleva",
		finalPrice: "Konečná cena",
		finalTotalPrice: "Konečná celková cena",
		estimatedShipDate: "Odhadované datum odeslání",
		notes: "Poznámky",
		shipTo: " Dopravit do",
		name: "název",
		UserLevelDiscount: "Sleva na uživatelské úrovni",
		check: "Šek",
		APOFPOAddress: "Adresa APO/FPO",
		Address: "Adresa",
		GrandTotal: "Celkový součet",
		AmountPaid: "Částka vyplacená",
		BalanceDue: "Nedoplatek",
	},
	Quotation: {
		setupFee: "Poplatek za nastavení",
		free: "VOLNÝ, UVOLNIT",
		company: "Společnost",
		off: "VYPNUTO",
		additionalCosts: "Další výdaje",
		firstOrderDiscount: "Sleva na první objednávku",
		specialGroup: "Speciální skupinové nabídky",
		pointsDeduct: "Odečtení bodů",
		giftVouchers: "dárkové poukázky",
		couponDiscount: "Kupónová sleva",
		goldVipMember: "Zlatý VIP člen",
		extraDiscount: "Extra sleva",
		userLevelDiscount: "Sleva na uživatelské úrovni",
		points: "Body",
		title: "CITÁT",
		to: "Na",
		from: "Z",
		name: "název",
		email: "E-mailem",
		telephone: "Telefon",
		country: "Země",
		oid: "číslo objednávky",
		date: "Datum nabídky",
		valid: "V platnosti, dokud",
		contacts: "Kontakty",
		currency: "Měna",
		no: "Ne.",
		item: "Položka",
		artwork: "Umělecké dílo",
		description: "Popis",
		qty: "Množství",
		unitPrice: "Jednotková cena",
		moldFee: "Poplatek za formu",
		extraFee: "Extra poplatek",
		apoShippingFee: "Poplatek za dopravu Apo",
		taxPrice: "Daňová cena",
		amount: "Množství",
		remarks: "Poznámky",
		total: "Celkový",
		titleTerms: "Všeobecné obchodní podmínky:",
		first: "1. Umělecká díla zdarma a doprava zdarma do USA / Kanady / Evropy.",
		second: "2. Výše uvedené informace nejsou fakturou, ale pouze odhadem výše popsaných služeb/zboží.",
		third: "3. Výroba bude zahájena v den, kdy obdržíme vaše potvrzení a platbu, zaplaťte prosím tento odkaz za vaši objednávku na základě schváleného dokladu. (platební odkaz:",
		end: "Děkuji za Váš obchod!",
		tel: "Tel",
		hours: "Úřední hodiny",
		chat: "Živý chat",
		address: "Adresa USA",
		hoursItem: "Pondělí až pátek 8:30 až 17:30 tichomořského času (PT)",
		chatItem: "24 hodin od pondělí do pátku",
		addressItem: "20829 Valley Blvd. Ořech, CA 91789",
	},
	member: {
		member1: "Stříbrný člen",
		member4: "Zlatý VIP člen",
		member5: "Platinový VIP člen",
	},
	...csLocale,
};
