import esLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Establecer tarifas",
		PleaseAttention: "Atención por favor.",
		MoldSetupFee: "Tarifas de molde / instalación",

		invoice: "FACTURA",
		PONumber: "numero postal",
		reference: "Referencia",
		HSCODE: "CÓDIGO HS",
		PURCHASEINVOICE: "FACTURA DE COMPRA",
		productPrintNo: "productoNo de impresión",
		BILLTO: "COBRAR A",
		SHIPTO: "ENVIE A",
		ProductDescription: "Descripción del Producto",
		total: "Total",
		PaymentStatus: "Estado de pago",
		checkInfo: "Verificar información",
		bankInfo: "Información bancaria",
		payOnline: "Paga en linea",
		checkPay: "Verifique: Pague y envíe por correo a la información a continuación",
		attentionPlease: "Recuerde",
		directorOperations: "director de operaciones",
		signature: "Firma",
		nodesText: "Si realiza el pago a través del banco, asegúrese de incluir su",
		nodesText2: "en la nota.",
		thankbusiness: "¡Gracias por hacer negocios!",
		paid: "PAGADO",
		unpaid: "NO PAGADO",
		payInstall: "Pago parcial, espere el saldo.",
		remember:
			' Para cuentas offshore y cuentas en moneda extranjera que utilizan clientes extranjeros para realizar remesas a nuestro banco, el uso de los fondos debe ser "Pago de bienes" o dejarlo en blanco.',
		extraText: [
			"",
			"Tarifa urgente",
			"Tarifa de impresión en serigrafía",
			"Tarifa del molde trasero",
			"Carga impresa",
			"Costo del material de fundición a presión de zinc",
			"Carga adicional",
			"Tarifa de productos con licencia",
			"Cantidad",
		],
		TaxPrice: "Precio del impuesto",
		discountedAmount: "Monto descontado",

		card: "1. Tarjeta de crédito: VISA / Mastercard / AMEX y Discover",
		bankNote:
			"Nota: Si desea pagar en una moneda diferente al EUR, póngase en contacto con nuestro representante de atención al cliente.",
		checkFollow: "2. Encuentre nuestra información de cheque de la siguiente manera:",
		mention: "Al realizar el pago, por favor mencione el número de factura. ¡Gracias!",
		topic: "Tema: Asesoramiento financiero",
		invoiceDate: "Fecha de la factura",
		invoiceNumber: "Número de factura",
		excluding: "Subtotal excluyendo",
		phone: "Teléfono",
		email: "Correo electrónico",
		from: "De",
		to: "A",
		attn: "Atención",
		lNVOICE: "FACTURA",
		PROFORMA: "FACTURA DE PROFORMA",
		invoiceNo: "Factura no",
		date: "Fecha",
		paymentMethod: "Método de pago",
		terms: "Términos",
		title: "Título",
		no: "No",
		artwork: "Obra de arte",
		item: "Artículo",
		description: "Descripción",
		qty: "Cantidad",
		unitPrice: "Precio unitario",
		moldFee: "Tarifa de molde",
		extraFee: "Suplemento",
		amount: "Cantidad",
		subtotal: "Total parcial",
		apoShippingFee: "Tarifa de envío Apo",
		taxPrice: "Precio de impuestos",
		couponAdditionalCosts: "Costo adicional",
		firstOrderCodeDisc: "Descuento por primer pedido",
		promoCode: "Código promocional",
		specialCode: "Ofertas especiales para grupos",
		custompensCode: "$1 por 20 bolígrafos",
		additionalCode: "$1 por un cupón de $75",
		pointsDeduct: "Deducción de puntos",
		points: "Puntos",
		giftVouchers: "Vales de regalo",
		couponDiscount: "Cupón de descuento",
		orderDiscount: "orden de descuento",
		extraDiscount: "Descuento extra",
		finalPrice: "Precio final",
		finalTotalPrice: "Precio Total Final",
		estimatedShipDate: "Día estimado de envío",
		notes: "notas",
		shipTo: " Envie a",
		name: "Nombre",
		check: "Controlar",
		APOFPOAddress: "Dirección APO/FPO",
		Address: "DIRECCIÓN",
		GrandTotal: "Gran total",
		AmountPaid: "Cantidad pagada",
		BalanceDue: "Saldo adeudado",
		UserLevelDiscount: "Descuento de nivel de usuario",
	},
	Quotation: {
		setupFee: "Tarifa de instalación",
		free: "GRATIS",
		company: "Compañía",
		off: "APAGADO",
		additionalCosts: "Costes adicionales",
		firstOrderDiscount: "Descuento por primer pedido",
		specialGroup: "Ofertas especiales para grupos",
		pointsDeduct: "Deducción de puntos",
		giftVouchers: "Vales de regalo",
		couponDiscount: "Cupón de descuento",
		goldVipMember: "Miembro VIP Oro",
		extraDiscount: "Descuento extra",
		userLevelDiscount: "Descuento de nivel de usuario",
		points: "Puntos",
		title: "COTIZACIÓN",
		to: "A",
		from: "De",
		name: "Nombre",
		email: "Correo electrónico",
		telephone: "Teléfono",
		country: "País",
		oid: "Solicitar ID",
		date: "Fecha de cotización",
		valid: "Válido hasta",
		contacts: "Contactos",
		currency: "Divisa",
		no: "No.",
		item: "Artículo",
		artwork: "Obra de arte",
		description: "Descripción",
		qty: "Cantidad",
		unitPrice: "Precio unitario",
		moldFee: "Tarifa de molde",
		extraFee: "Suplemento",
		apoShippingFee: "Tarifa de envío Apo",
		taxPrice: "Precio del impuesto",
		amount: "Cantidad",
		remarks: "Observaciones",
		total: "Total",
		titleTerms: "Términos y condiciones:",
		first: "1. Obras de arte gratuitas y envío gratuito a EE. UU./Canadá/Europa.",
		second: "2. La información anterior no es una factura y solo una estimación de los servicios/bienes descritos anteriormente.",
		third: "3. La producción comenzará en la fecha en que recibamos la aprobación y el pago de la prueba. Pague este enlace por su pedido según la prueba aprobada. (Enlace de pago:",
		end: "¡Gracias por hacer negocios!",
		tel: "Teléfono",
		hours: "Horas de oficina",
		chat: "Chat en vivo",
		address: "Dirección de EE. UU.",
		hoursItem: "Lunes - Viernes 8:30 AM a 5:30 PM Hora del Pacífico (PT)",
		chatItem: "24 horas de lunes a viernes",
		addressItem: "20829 bulevar del valle. Nogal, CA 91789",
	},
	member: {
		member1: "Miembro Plata",
		member4: "Miembro VIP Oro",
		member5: "Miembro VIP Platino",
	},
	...esLocale,
};
