import huLocale from "element-ui/lib/locale/lang/en";
export default {
	Invoice: {
		SetupFee: "Beállítási díj",
		PleaseAttention: "Kérem, figyelem.",
		MoldSetupFee: "Penész/beállítási díj",

		invoice: "SZÁMLA",
		PONumber: "PO szám",
		reference: "Referencia",
		HSCODE: "HS KÓD",
		PURCHASEINVOICE: "VÁSÁRLÁSI SZÁMLA",
		productPrintNo: "termék Nyomtatási sz",
		BILLTO: "BILL TO",
		SHIPTO: "KISZÁLLÍTÁS",
		ProductDescription: "termékleírás",
		total: "Teljes",
		PaymentStatus: "Fizetési állapot",
		checkInfo: "Ellenőrizze az Információkat",
		bankInfo: "Bank Info",
		payOnline: "Online fizetés",
		checkPay: "Ellenőrzés: Fizessen, és küldje el az alábbi információkra",
		attentionPlease: "Ne feledje",
		directorOperations: "Műveleti igazgató",
		signature: "Aláírás",
		nodesText: "Ha bankon keresztül fizet, kérjük, feltétlenül tüntesse fel",
		nodesText2: "a feljegyzésben.",
		thankbusiness: "Köszönjük üzletét!",
		paid: "FIZETETT",
		unpaid: "KIFIZETETLEN",
		payInstall: "Részleges fizetés, várja meg az egyenleget.",
		remember:
			" Azon offshore számláknál és devizaszámláknál, amelyeket külföldi ügyfelek bankunkba történő utaláshoz használnak, a pénzeszközök felhasználása az „Áruk fizetése” legyen, vagy hagyja üresen.",
		extraText: [
			"",
			"Rush Fee",
			"Szitanyomtatási díj",
			"Vissza Penészdíj",
			"Nyomtatott díj",
			"Cink présöntvény anyagköltsége",
			"Extra fuvar",
			"Licenctermékek díja",
			"Mennyiség",
		],
		TaxPrice: "Adóár",
		discountedAmount: "Kedvezményes összeg",

		card: "1. Hitelkártya: VISA / Mastercard / AMEX és Discover",
		bankNote:
			"Megjegyzés: Ha eurótól eltérő pénznemben szeretne fizetni, kérjük, lépjen kapcsolatba ügyfélszolgálatunkkal.",
		checkFollow: "2. Kérjük, keresse meg csekkinformációinkat az alábbiak szerint:",
		mention: "Fizetéskor kérjük, tüntesse fel a számla számát. Köszönöm!",
		topic: "Téma: Pénzügyi tanácsadás",
		invoiceDate: "Számla kiállítási dátuma",
		invoiceNumber: "Számlaszám",
		excluding: "Részösszeg, kivéve",
		phone: "Telefon",
		email: "Email",
		from: "Tól től",
		to: "Nak nek",
		attn: "Attn",
		lNVOICE: "SZÁMLA",
		PROFORMA: "PROFORMA SZÁMLA",
		invoiceNo: "Nincs számla",
		date: "Dátum",
		paymentMethod: "Fizetési mód",
		terms: "Feltételek",
		title: "Cím",
		no: "Nem",
		artwork: "alkotás",
		item: "Tétel",
		description: "Leírás",
		qty: "Menny",
		unitPrice: "Egységár",
		moldFee: "Penészdíj",
		extraFee: "Extra költség",
		amount: "Összeg",
		subtotal: "Részösszeg",
		apoShippingFee: "Apo Szállítási díj",
		taxPrice: "Adóár",
		couponAdditionalCosts: "Többletköltség",
		firstOrderCodeDisc: "Első rendelési kedvezmény",
		promoCode: "Promóciós kód",
		specialCode: "Különleges csoportos ajánlatok",
		custompensCode: "1 dollár 20 db tollért",
		additionalCode: "1 dollár egy 75 dolláros utalványért",
		pointsDeduct: "Pontok levonása",
		points: "Pontok",
		giftVouchers: "ajándékutalványok",
		couponDiscount: "Kupon kedvezmény",
		orderDiscount: "Rendelési kedvezmény",
		extraDiscount: "Extra kedvezmény",
		finalPrice: "Végső ár",
		finalTotalPrice: "Végső teljes ár",
		estimatedShipDate: "Becsült szállítási dátum",
		notes: "Megjegyzések",
		shipTo: " Kiszállítás",
		name: "Név",
		check: "Jelölje be",
		APOFPOAddress: "APO/FPO cím",
		Address: "Cím",
		GrandTotal: "Teljes összeg",
		AmountPaid: "Kifizetett összeg",
		BalanceDue: "Esedékes egyenleg",
		UserLevelDiscount: "Felhasználói szintű kedvezmény",
	},
	Quotation: {
		setupFee: "Beállítási díj",
		free: "INGYENES",
		company: "Vállalat",
		off: "KI",
		additionalCosts: "További költségek",
		firstOrderDiscount: "Első rendelési kedvezmény",
		specialGroup: "Különleges csoportos ajánlatok",
		pointsDeduct: "Pontok levonása",
		giftVouchers: "ajándékutalványok",
		couponDiscount: "Kupon kedvezmény",
		goldVipMember: "Arany VIP tag",
		extraDiscount: "Extra kedvezmény",
		userLevelDiscount: "Felhasználói szintű kedvezmény",
		points: "Pontok",
		title: "IDÉZET",
		to: "Nak nek",
		from: "Tól től",
		name: "Név",
		email: "Email",
		telephone: "telefon",
		country: "Ország",
		oid: "Rendelés azonosító",
		date: "Árajánlat dátuma",
		valid: "Ig érvényes",
		contacts: "Kapcsolatok",
		currency: "Valuta",
		no: "Nem.",
		item: "Tétel",
		artwork: "alkotás",
		description: "Leírás",
		qty: "Mennyiség",
		unitPrice: "Egységár",
		moldFee: "Penészdíj",
		extraFee: "Extra költség",
		apoShippingFee: "Apo Szállítási díj",
		taxPrice: "Adóár",
		amount: "Összeg",
		remarks: "Megjegyzések",
		total: "Teljes",
		titleTerms: "Felhasználási feltételek:",
		first: "1. Ingyenes műalkotás és ingyenes szállítás az USA-ba / Kanadába / Európába.",
		second: "2. A fenti információ nem számla, hanem csak a fent leírt szolgáltatások/áruk becslése.",
		third: "3.A gyártás azon a napon kezdődik, amikor megkapjuk az igazolás jóváhagyását és kifizetését, kérjük, fizesse ki ezt a linket a megrendeléséhez a jóváhagyott bizonyíték alapján. (Fizetési link:",
		end: "Köszönjük üzletét!",
		tel: "Tel",
		hours: "Munkaidő",
		chat: "Élő chat",
		address: "USA cím",
		hoursItem: "Hétfőtől péntekig 8:30 és 17:30 között csendes-óceáni idő (PT)",
		chatItem: "24 óra hétfőtől péntekig",
		addressItem: "20829 Valley Blvd. Dió, CA 91789",
	},
	member: {
		member1: "Ezüst tag",
		member4: "Arany VIP tag",
		member5: "Platina VIP tag",
	},
	...huLocale,
};
