import request from "@/utils/request";

export function editAuth(data) {
	return request({
		url: "/user/auth/editAuth",
		method: "post",
		data: data,
	});
}

export function getAllAuth(data) {
	return request({
		url: "/user/auth/getAllAuth",
		method: "post",
		data: data,
	});
}

//获取角色列表
export function getRoleList(data) {
	return request({
		url: "/user/getRoleList",
		method: "post",
		data: data,
	});
}

//编辑角色
export function editRole(data) {
	return request({
		url: "/user/editRole",
		method: "post",
		data: data,
	});
}

//删除角色
export function deleteRole(data) {
	return request({
		url: "/user/deleteRole",
		method: "get",
		params: data,
	});
}

//获取用户列表
export function getUserListByPost(data) {
	return request({
		url: "/user/getUserList",
		method: "post",
		data: data,
	});
}

//获取用户列表
export function getUserListByGet(data) {
	return request({
		url: "/user/getUserList",
		method: "get",
		params: data,
	});
}

//获取用户列表
export function getUserListByRoleIdAndDeptId(data) {
	return request({
		url: "/user/getUserListByRoleIdAndDeptId",
		method: "get",
		params: data,
	});
}

//解绑
export function unbind(data) {
	return request({
		url: "/user/qyWx/unbind",
		method: "post",
		data,
	});
}
//编辑用户
export function editUser(data) {
	return request({
		url: "/user/editUser",
		method: "post",
		data: data,
		notRemoveEmptyParams: true,
	});
}

//角色授权
export function doRoleAuth(data) {
	return request({
		url: "/user/auth/doRoleAuth",
		method: "post",
		data: data,
	});
}

//用户授权
export function doUserAuth(data) {
	return request({
		url: "/user/auth/doUserAuth",
		method: "post",
		data: data,
	});
}

//获取用户权限列表
export function getUserAuthList(data) {
	return request({
		url: "/user/auth/getUserAuthList",
		method: "get",
		params: data,
	});
}

//获取角色权限列表
export function getRoleAuthList(data) {
	return request({
		url: "/user/auth/getRoleAuthList",
		method: "get",
		params: data,
	});
}

//获取网站项目列表
export function getSystemProList(data) {
	return request({
		url: "/systemProject/list",
		method: "get",
		params: data,
	});
}

//编辑网站项目
export function editProject(data) {
	return request({
		url: "/systemProject/edit",
		method: "post",
		data: data,
	});
}

//获取国家列表
export function getCountryList(data) {
	return request({
		url: "/address/country/getCountryList",
		method: "get",
		params: data,
	});
}

//删除国家
export function delCountryById(data) {
	return request({
		url: "/address/country/delById",
		method: "get",
		params: data,
	});
}

//更新国家
export function updateCountry(data) {
	return request({
		url: "/address/country/update",
		method: "post",
		data: data,
	});
}

//获取省份
export function getState(data) {
	return request({
		url: "/address/country/getState",
		method: "post",
		data: data,
	});
}

//添加省份
export function addState(data) {
	return request({
		url: "/address/countryStates/add",
		method: "post",
		data: data,
	});
}

//更新省份
export function updateState(data) {
	return request({
		url: "/address/countryStates/update",
		method: "post",
		data: data,
	});
}

//删除省份
export function delStateById(data) {
	return request({
		url: "/address/countryStates/delById",
		method: "get",
		params: data,
	});
}

//获取部门列表
export function getDeptList(data) {
	return request({
		url: "/user/dept/getDeptList",
		method: "get",
		params: data,
	});
}

//获取所有部门列表不需权限
export function getAllDeptList(data) {
	return request({
		url: "/user/dept/getAllDeptList",
		method: "get",
		params: data,
	});
}

//根据部门获取角色
export function getRoleListByDeptId(data) {
	return request({
		url: "/user/getRoleListByDeptId",
		method: "get",
		params: data,
	});
}
//编辑/添加部门
export function editDept(data) {
	return request({
		url: "/user/dept/editDept",
		method: "post",
		data: data,
	});
}
//获取部门权限列表
export function getDeptAuthList(data) {
	return request({
		url: "/user/auth/getDeptAuthList",
		method: "get",
		params: data,
	});
}
//部门授权
export function doDeptAuth(data) {
	return request({
		url: "/user/auth/doDeptAuth",
		method: "post",
		data: data,
	});
}

//获取业务员列表
export function getUserListByDeptId(data) {
	return request({
		url: "/user/getUserListByDeptId",
		method: "get",
		params: data,
	});
}

//根据部门id获取部门所有角色和所有系统角色
export function getDeptListAndSystemRoleList(data) {
	return request({
		url: "/user/getDeptListAndSystemRoleList",
		method: "get",
		params: data,
	});
}
//获取所有系统角色
export function getSystemRoleList(data) {
	return request({
		url: "/user/getSystemRoleList",
		method: "get",
		params: data,
	});
}

//获取所有系统角色
export function updateIsSystemRole(data) {
	return request({
		url: "/user/updateIsSystemRole",
		method: "get",
		params: data,
	});
}

//获取全部产品类别
export function getAllTaskCate(data) {
	return request({
		url: "/newTask/taskCate/getAllTaskCate",
		method: "get",
		params: data,
	});
}

//获取全部产品类别
export function getPurchaseTypeList(data) {
	return request({
		url: "/new/commonParam/getPurchaseTypeList",
		method: "get",
		params: data,
	});
}

//获取全部办公室信息
export function getAllList(data) {
	return request({
		url: "/new/officeInfo/getAllList",
		method: "get",
		params: data,
	});
}

//获取全部办公室查询列表
export function getOfficeList(data) {
	return request({
		url: "/new/officeInfo/list",
		method: "get",
		params: data,
	});
}
//新增办公室信息
export function AddOfficeInfo(data) {
	return request({
		url: "/new/officeInfo/saveOfficeInfo",
		method: "post",
		data,
	});
}
//获取洲
export function getContinentList() {
	return request({
		url: "/address/continent/getContinentList",
		method: "get",
	});
}

//获取货币
export function getAllCurrency() {
	return request({
		url: "/quote/currency/getAllCurrency",
		method: "get",
	});
}
//获取用户集合
export function getUserMap() {
	return request({
		url: "/new/productionOrder/getUserMap",
		method: "get",
	});
}

export function getSystemProListPost(data) {
	return request({
		url: "/systemProject/list",
		method: "post",
		data: data,
	});
}

// 在线用户清单
export function getOnlineUserList(data) {
	return request({
		url: "/wsManager/broadcast/onlineUserList",
		method: "get",
		params: data,
	});
}
// 给所有人发送
export function sendMessageToAll(data) {
	return request({
		url: "/wsManager/broadcast/sendMessageToAll",
		method: "post",
		data: data,
	});
}

// 给指定用户发送
export function sendMessageToUser(data) {
	return request({
		url: "/wsManager/broadcast/sendMessageToUser",
		method: "post",
		data: data,
	});
}
// 查询发送记录
export function broadcastHistoryList(data) {
	return request({
		url: "/wsManager/broadcast/broadcastHistoryList",
		method: "get",
		params: data,
	});
}
// 获取部门中多企业微信绑定的账号
export function getUserOfMultiWxByDeptId(data) {
	return request({
		url: "/user/qyWx/getUserOfMultiWxByDeptId",
		method: "get",
		params: data,
	});
}
// 多企业微信绑定设置接收消息账号
export function setReceiveMessage(data) {
	return request({
		url: "/user/qyWx/setReceiveMessage",
		method: "post",
		data,
	});
}
