import layout from "@/views/layout/index.vue";

const designer = () => import("@/views/designerManagement/designer.vue");
const dashBoard = () => import("@/views/designerManagement/dashBoard.vue");
export default {
	path: "/designerManagement",
	name: "designerManagement",
	component: layout,
	meta: {
		title: "设计师管理",
		icon: "el-icon-s-cooperation",
	},
	children: [
		{
			path: "designer",
			name: "designer",
			component: designer,
			meta: {
				title: "设计师",
			},
		},
		{
			path: "dashBoard",
			component: dashBoard,
			name: "dashBoard",
			meta: {
				title: "DashBoard",
			},
		},
	],
};
