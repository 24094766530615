import layout from "@/views/layout/index.vue";
import userModule from "@/views/InquiryOrderManage/userModule/index.vue";
const threeDManage = () => import("@/views/InquiryOrderManage/threeDManage.vue");
const allocationConfiguration = () => import("@/views/InquiryOrderManage/allocationConfiguration.vue");
const inquiryStatistics = () => import("@/views/InquiryOrderManage/inquiryStatistics.vue");
const orderTimeliness = () => import("@/views/InquiryOrderManage/orderTimeliness.vue");
const marketingorderIndex = () => import("@/views/InquiryOrderManage/marketingorderIndex/index.vue");
// const marketingordertracking = () => import("@/views/InquiryOrderManage/marketingordertracking.vue");
const inquiry = () => import("@/views/InquiryOrderManage/inquiry/index.vue");
const inquiryManage = () => import("@/views/InquiryOrderManage/inquiry/inquiryManage.vue");
const inquiryDetail = () => import("@/views/InquiryOrderManage/inquiry/inquiryDetail.vue");
const order = () => import("@/views/InquiryOrderManage/order/index.vue");
const linkOrder = () => import("@/views/InquiryOrderManage/linkOrder/index.vue");
const orderManage = () => import("@/views/InquiryOrderManage/order/orderManage.vue");
const linkOrderManage = () => import("@/views/InquiryOrderManage/linkOrder/linkOrderManage.vue");
const refundForm = () => import("@/views/InquiryOrderManage/order/refundForm.vue");
const orderDetail = () => import("@/views/InquiryOrderManage/order/orderDetail.vue");
const uploadDraft = () => import("@/views/InquiryOrderManage/order/uploadDraft.vue");
const financialOffline = () => import("@/views/financial/financialOffline.vue");
const inquiryBacklogStatistics = () => import("@/views/InquiryOrderManage/inquiryBacklogStatistics.vue");
const sampleOrderUpload = () => import("@/views/InquiryOrderManage/sampleOrderUpload.vue");
export default {
	path: "/InquiryOrderManage",
	component: layout,
	name: "InquiryOrderManage",
	meta: {
		title: "询盘订单管理",
		icon: "el-icon-s-order",
	},
	children: [
		{
			path: "inquiry",
			name: "inquiry",
			component: inquiry,
			meta: {
				title: "询盘",
			},
			children: [
				{
					path: "inquiryManage",
					name: "inquiryManage",
					component: inquiryManage,
					meta: {
						title: "询盘列表",
					},
				},
				{
					path: ":id",
					name: "inquiryDetail",
					component: inquiryDetail,
					hidden: true,
					meta: {
						title: "询盘详情",
					},
				},
			],
		},

		{
			path: "linkOrder",
			name: "linkOrder",
			component: linkOrder,
			meta: {
				title: "链接订单",
			},
			children: [
				{
					path: "linkOrderManage",
					name: "linkOrderManage",
					component: linkOrderManage,
					meta: {
						title: "链接订单",
					},
				},
				{
					path: "uploadDraft",
					name: "linkUploadDraft",
					component: uploadDraft,
					hidden: true,
					meta: {
						title: "图稿上传",
					},
				},
				{
					path: ":id",
					name: "linkOrderDetail",
					component: orderDetail,
					hidden: true,
					meta: {
						title: "订单详情",
					},
				},
			],
		},
		{
			path: "order",
			name: "order",
			component: order,
			meta: {
				title: "订单",
			},
			children: [
				{
					path: "orderManage",
					name: "orderManage",
					component: orderManage,
					meta: {
						title: "直接订单",
					},
				},
				{
					path: "uploadDraft",
					name: "uploadDraft",
					component: uploadDraft,
					hidden: true,
					meta: {
						title: "图稿上传",
					},
				},
				{
					path: ":id",
					name: "orderDetail",
					component: orderDetail,
					hidden: true,
					meta: {
						title: "订单详情",
					},
				},
			],
		},
		{
			path: "threeDManage",
			name: "threeDManage",
			component: threeDManage,
			meta: {
				title: "3D订单管理",
			},
		},
		{
			path: "allocationConfiguration",
			name: "allocationConfiguration",
			component: allocationConfiguration,
			meta: {
				title: "分配配置",
			},
		},
		{
			path: "inquiryStatistics",
			name: "inquiryStatistics",
			component: inquiryStatistics,
			meta: {
				title: "询盘订单统计",
			},
		},
		//未成单
		{
			path: "inquiryBacklogStatistics",
			name: "inquiryBacklogStatistics",
			component: inquiryBacklogStatistics,
			meta: {
				title: "询盘未成单原因占比统计",
			},
		},
		{
			path: "",
			redirect: "userModule",
		},
		{
			path: "userModule",
			name: "userModule",
			component: userModule,
			meta: {
				title: "用户模块",
			},
		},
		{
			path: "orderTimeliness",
			name: "orderTimeliness",
			component: orderTimeliness,
			meta: {
				title: "订单时效管理",
			},
		},
		{
			path: "marketingordertracking",
			name: "marketingordertracking",
			component: marketingorderIndex,
			meta: {
				title: "营销订单追踪",
			},
		},
		{
			path: "/financialOffline",
			name: "financialOffline",
			component: financialOffline,
			meta: {
				title: "财务线下收款",
			},
		},
		{
			path: "sampleOrderUpload",
			name: "sampleOrderUpload",
			component: sampleOrderUpload,
			meta: {
				title: "随机样品订单上传",
			},
		},
	],
};
