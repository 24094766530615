import layout from "@/views/layout/index.vue";

const memberManage = () => import("@/views/userCenter/memberManage.vue");
const memberLevel = () => import("@/views/userCenter/memberLevel.vue");
const memberAddress = () => import("@/views/userCenter/memberAddress.vue");
const memberOrderDetail = () => import("@/views/userCenter/memberOrderDetail.vue");
const customerTrackingManage = () => import("@/views/userCenter/customerTrackingManage.vue");

export default {
	path: "/userCenter",
	name: "userCenter",
	component: layout,
	meta: {
		title: "用户管理",
		icon: "el-icon-user-solid",
	},
	children: [
		{
			path: "memberManage",
			component: memberManage,
			name: "memberManage",
			meta: {
				title: "会员信息管理",
			},
		},
		{
			path: "memberLevel",
			component: memberLevel,
			name: "memberLevel",
			meta: {
				title: "会员等级管理",
			},
		},
		{
			path: "memberAddress",
			component: memberAddress,
			name: "memberAddress",
			meta: {
				title: "经销商地址管理",
			},
		},
		{
			path: "customerTrackingManage",
			component: customerTrackingManage,
			name: "customerTrackingManage",
			meta: {
				title: "待跟踪客户管理",
			},
		},
		{
			path: "memberOrderDetail",
			component: memberOrderDetail,
			name: "memberOrderDetail",
			meta: {
				title: "用户订单详情",
			},
		},
	],
};
